<template>
  <div class="login-page">
    
    <div class="logo-title">
      <div>{{ $store.getters.appName }}</div>
    </div>
    <div class="center">
      <CommonPage></CommonPage>
    </div>
    <div class="footer">
      <div v-if="footMsg" style="margin-bottom: 5px;"> {{footMsg}}</div>
      <div v-if="icp">
        备案号：<a  href="https://beian.miit.gov.cn/" target="_blank">{{icp}}</a> 
      </div>
    </div>
  </div>
</template>

<script>
import CommonPage from './common/index.vue'
export default {
  components: { CommonPage },
  data() {
    return {
      footMsg: '',
      icp: ''
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
$width: calc(100% - 40px);
$height: 45px;

.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  font-size: 8px;
  &::before {
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    bottom: 30%;
    top: 0px;
    background-size: 100% 100%;
    background-image: url('../../assets/img/bg/1.png');
  }
  .center {
    position: fixed;
    bottom: calc(30% - 110px);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    // position: relative;
    // padding-bottom: 400px;
    width: 90%;
    max-width: 400px;
    // padding-bottom: 50px;
    height: 400px;
    margin-top: 80px;
    z-index: 1;
    // overflow: hidden;
    // box-shadow: 0px 2px 15px #ccc;
    &::after {
      position: absolute;
      content: '';
      bottom: 10px;
      left: -20px;
      right: -20px;
      height: 50px;
      // background-color: red;
      background-size: 100% auto;
      background-image: url('../../assets/img/bg/2.png');
    }
  }

  .logo {
    width: 120px;
    background-color: rgba($color: $primary-color, $alpha: 0.9);
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
.footer {
  position: fixed;
  bottom: 60px;
  font-size: 12px;
  color: #999;
}
.logo-title {
  position: fixed;
  bottom: calc(50% + 180px);
  // margin-top: -120px;
  color: #666;
  font-size: 32px;
  font-weight: 700;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 1);
  letter-spacing: 0.3em;
  width: auto;
  font-family: 'syst';
  .zw {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.4);
    letter-spacing: 0.15em;
    font-family: 'syst';
    font-weight: 400;
  }
}
@media screen and (max-width: 750px) {
  .logo-title {
    letter-spacing: 0em;
    .zw {
      font-size: 18px;
      letter-spacing: 0em;
    }
  }
}
</style>